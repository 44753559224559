.wrapper {
	position: relative;
	top: 0;
	height: 100vh;

	&.wrapper-full-page {
		min-height: 100vh;
		height: auto;
	}
}

.sidebar,
.off-canvas-sidebar {
	position: fixed;
	top: 0;
	height: 100%;
	bottom: 0;
	width: 260px;
	left: 0;
	z-index: 1030;
	border-right: 1px solid #ddd;

	.sidebar-wrapper {
		position: relative;
		height: calc(100vh - 75px);
		overflow: auto;
		width: 260px;
		z-index: 4;
		padding-bottom: 100px;

		.dropdown .dropdown-backdrop {
			display: none !important;
		}

		.navbar-form {
			border: none;
		}
	}

	.navbar-minimize {
		position: absolute;
		right: 20px;
		top: 2px;
		opacity: 1;

		@extend .animation-transition-general;
	}
	.logo-tim {
		border-radius: 50%;
		border: 1px solid #333;
		display: block;
		height: 61px;
		width: 61px;
		float: left;
		overflow: hidden;

		img {
			width: 60px;
			height: 60px;
		}
	}

	.nav {
		margin-top: 20px;
		display: block;

		.caret {
			top: 14px;
			position: absolute;
			right: 10px;
		}

		li {
			> a + div .nav li > a {
				margin-top: 7px;
			}

			> a {
				margin: 10px 15px 0;
				color: $white-color;
				display: block;
				text-decoration: none;
				position: relative;
				text-transform: uppercase;
				cursor: pointer;
				font-size: 12px;
				padding: 10px 8px;
				line-height: 30px;
				opacity: 0.7;
			}

			.nav > li > a {
				padding: 5px 8px;
			}

			&.active > a,
			&.active > a > i {
				opacity: 1;
			}

			&:hover:not(.active) > a,
			&:focus:not(.active) > a {
				opacity: 1;
			}
		}

		i {
			font-size: 24px;
			float: left;
			margin-right: 12px;
			line-height: 30px;
			width: 34px;
			text-align: center;
			color: $opacity-5;
			position: relative;
		}

		p {
			margin-bottom: 0;
		}

		.collapse,
		.collapsing {
			.nav {
				margin-top: 0;
			}
		}
	}

	.sidebar-background {
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		display: block;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center center;

		&:after {
			position: absolute;
			z-index: 3;
			width: 100%;
			height: 100%;
			content: '';
			display: block;
			background: #ccc;
			opacity: 1;
		}
	}

	.logo {
		position: relative;
		padding: 7px $padding-base-horizontal;
		z-index: 4;

		a.logo-mini,
		a.logo-normal {
			@extend .animation-transition-general;
		}

		a.logo-mini {
			opacity: 1;
			float: left;
			width: 34px;
			text-align: center;
			margin-left: 10px;
			margin-right: 12px;
		}

		a.logo-normal {
			display: block;
			opacity: 1;
			padding: 11px 0 8px;
			@include transform-translate-x(0px);
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 15px;
			height: 1px;
			width: calc(100% - 30px);
			background-color: $opacity-5;
		}

		p {
			float: left;
			font-size: 20px;
			margin: 10px 10px;
			color: $white-color;
			line-height: 20px;
			font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
		}

		.simple-text {
			text-transform: uppercase;
			padding: $padding-base-vertical 0;
			display: block;
			white-space: nowrap;
			font-size: $font-size-large;
			color: $white-color;
			text-decoration: none;
			font-weight: $font-weight-normal;
			line-height: 30px;
			overflow: hidden;
		}
	}

	.logo-tim {
		border-radius: 50%;
		border: 1px solid #333;
		display: block;
		height: 61px;
		width: 61px;
		float: left;
		overflow: hidden;

		img {
			width: 60px;
			height: 60px;
		}
	}

	&:before,
	&:after {
		display: block;
		content: '';
		opacity: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:after {
		// @include icon-gradient($default-color);
		background-color: #1e1e24;
		z-index: 3;
	}

	&[data-color='white'] {
		@include sidebar-color($white-color);
		@include sidebar-text-color($default-color);
	}
	&[data-color='black'] {
		@include sidebar-color($dark-color);
	}

	// Active color changing

	&[data-active-color='primary'] {
		@include sidebar-active-color($primary-color);
	}
	&[data-active-color='info'] {
		@include sidebar-active-color($info-color);
	}
	&[data-active-color='success'] {
		@include sidebar-active-color($success-color);
	}
	&[data-active-color='warning'] {
		@include sidebar-active-color($warning-color);
	}
	&[data-active-color='danger'] {
		@include sidebar-active-color($danger-color);
	}
}

.visible-on-sidebar-regular {
	display: inline-block !important;
}
.visible-on-sidebar-mini {
	display: none !important;
}

.off-canvas-sidebar {
	.nav {
		> li > a,
		> li > a:hover {
			color: $white-color;
		}

		> li > a:focus {
			background: rgba(200, 200, 200, 0.2);
		}
	}
}

.main-panel {
	position: relative;
	float: right;
	width: $sidebar-width;
	background-color: #f4f3ef;

	@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

	> .content {
		padding: 0 30px 30px;
		min-height: calc(100vh - 162px);
		margin-top: 93px;
	}

	> .navbar {
		margin-bottom: 0;
	}

	.header {
		margin-bottom: 50px;
	}
}

.perfect-scrollbar-on {
	.sidebar,
	.main-panel {
		height: 100%;
		max-height: 100%;
	}
}

.panel-header {
	height: 260px;
	padding-top: 80px;
	padding-bottom: 45px;
	background: #141e30; /* fallback for old browsers */
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#0c2646),
		color-stop(60%, #204065),
		to(#2a5788)
	);
	background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
	position: relative;
	overflow: hidden;

	.header {
		.title {
			color: $white-color;
		}
		.category {
			max-width: 600px;
			color: $opacity-5;
			margin: 0 auto;
			font-size: 13px;

			a {
				color: $white-color;
			}
		}
	}
}

.panel-header-sm {
	height: 135px;
}

.panel-header-lg {
	height: 380px;
}
